import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import { Carousel } from 'react-responsive-carousel'

const PortretsContent = () => {
  const data = useStaticQuery(graphql`
    {
      allPortfolioJson {
        edges {
          node {
            portrets {
              image
              name
              width
              height
            }
          }
        }
      }
    }
  `)
  return data.allPortfolioJson.edges.map ( ({node}) => {
    const {portrets} = node;
    
    return <div className = 'portfolio-slider' key = {portrets}>
      <Carousel
        infiniteLoop
        autoPlay
      >        
        {portrets.map (portretsItem => <div className = 'portfolio__slide' key = {portretsItem.image}>
            <img src = {portretsItem.image} alt = {portretsItem.name}/> <br/>
            <div className = 'portfolio__slide__info'>
              <div className = 'portfolio__slide__info_title'>
                {portretsItem.name}
              </div>
              <div className = 'portfolio__slide__info_description'>
                Амбротип {portretsItem.width} X {portretsItem.height}
              </div>
            </div>
          </div>
        )}
      </Carousel>
      </div>
  }
  )
}

export default PortretsContent;
