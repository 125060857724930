import React from 'react'
import Layout from '../../components/Layout'
import PortretsContent from '../../components/PortretsContent'

export default function Portfolio() {
  return (
    <Layout>
      <PortretsContent />
    </Layout>
  );
}
